<template>
  <div id="app">
    <div class="navbar bg-neutral">
      <div class="navbar-center mx-auto">
        <img src="./assets/logo.png" style="height: 60px" alt="Boon Logo" />
      </div>
    </div>
    <div class="container mx-auto">
      <router-view />
    </div>
    <div class="btm-nav fixed inset-x-0 bottom-0 z-10 bg-neutral">
      <router-link :class="page.getPage == 'menu' ? 'active' : ''" to="/">
        <ion-icon name="restaurant"></ion-icon>
        <span class="btm-nav-label">Menu</span>
      </router-link>
      <router-link :class="page.getPage == 'cart' ? 'active' : ''" to="/cart">
        <!-- <ion-icon name="cart"></ion-icon> -->
        <ion-icon name="bag-handle"></ion-icon>
        <span class="btm-nav-label">Cart</span>
      </router-link>
      <button :class="page.getPage == 'customer' ? 'active' : ''">
        <ion-icon name="person"></ion-icon>
        <span class="btm-nav-label">Details</span>
      </button>
      <button
        v-show="theme == 'luxury'"
        data-set-theme="boon"
        @click="theme = 'boon'"
        data-act-class="boon"
      >
        <ion-icon name="sunny"></ion-icon>
        <span class="btm-nav-label">Theme</span>
      </button>
      <button
        v-show="theme == 'boon'"
        data-set-theme="luxury"
        @click="theme = 'luxury'"
        data-act-class="luxury"
      >
        <ion-icon name="moon"></ion-icon>
        <span class="btm-nav-label">Theme</span>
      </button>
    </div>
    <div class="toast toast-top toast-end mt-2">
      <div v-show="alert.type == 'info'" class="alert alert-info text-white">
        <div>
          <span>{{ alert.message }}</span>
        </div>
      </div>
      <div v-show="alert.type == 'error'" class="alert alert-error">
        <div>
          <ion-icon name="alert-circle"></ion-icon>
          <span>{{ alert.message }}</span>
        </div>
      </div>
      <div v-show="alert.type == 'success'" class="alert alert-success">
        <div>
          <span>{{ alert.message }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { themeChange } from "theme-change";
import { computed, onMounted, ref, watch } from "vue";
import { useAlertStore } from "./store/alert";
import { usePageStore } from "./store/page";

export default {
  setup() {
    const theme = ref(localStorage.getItem("theme"));
    const alertStore = useAlertStore();
    const alert = computed(() => alertStore.getAlert);
    onMounted(() => {
      themeChange(false);
    });
    watch(alert, (newVal, old) => {
      if (newVal.type == old.type || newVal.type != null) {
        setTimeout(() => alertStore.reset(), 5000);
      }
    });
    const page = usePageStore();
    return {
      theme,
      alert,
      page,
    };
  },
};
</script>
