import Order from "./views/Order.vue";
import Cart from "./views/Cart.vue";

export const routes = [
  {
    path: "/",
    component: Order,
  },
  {
    path: "/cart",
    component: Cart,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];
