<template>
  <div>
    <div v-if="menu">
      <div class="container overflow-x-auto">
        <div v-for="(section, index) in menu.sections" :key="index" class="">
          <div class="divider">
            <div class="text-2xl">{{ section.title }}</div>
          </div>
          <table class="w-full table-auto table-zebra table-responsive">
            <thead class="bg-neutral border-b-2">
              <tr>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">
                  Name
                </th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">
                  Unit
                </th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">
                  Quantity
                </th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">
                  Price
                </th>
                <th class="p-3 text-sm font-semibold tracking-wide text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(food_section, index) in section.foods_section"
                :key="index"
              >
                <td class="p-3 text-sm">{{ food_section.food.name }}</td>
                <td class="p-3 text-sm">
                  {{ food_section.food.quantity }}
                </td>
                <td class="p-3 text-sm">{{ food_section.food.unit }}</td>
                <td class="p-3 text-sm">{{ food_section.food.price }}</td>
                <td class="p-3 space-x-5">
                  <div class="flex">
                    <div class="md:flex-col">
                      <button
                        class="inline-flex items-center justify-center w-8 h-8 mr-2 transition-colors duration-150 bg-neutral rounded-lg focus:shadow-outline"
                        @click="cart.decrementOrderCountFor(food_section.id)"
                      >
                        <span class="h-6 w-4"> - </span>
                      </button>
                      <div
                        class="inline-flex text-sm items-center justify-center w-8 h-8 mr-2"
                      >
                        {{ cart.getOrderCountFor(food_section.id) }}
                      </div>
                      <button
                        class="inline-flex items-center justify-center w-8 h-8 mr-2 transition-colors duration-150 bg-neutral rounded-lg focus:shadow-outline"
                        @click="cart.incrementOrderCountFor(food_section.id)"
                      >
                        <span class="h-6 w-4"> + </span>
                      </button>
                    </div>
                    <div class="inline-flex items-center justify-center">
                      <button
                        class="inline-flex text-pink-100 transition-colors w-8 h-8 mr-2 duration-150 bg-secondary rounded-full focus:shadow-outline hover:bg-pink-800"
                        @click="cart.addToCart(section.title, food_section)"
                      >
                        <ion-icon
                          name="bag-handle-outline"
                          class="p-1"
                        ></ion-icon>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mx-auto w-9/12 card shadow-xl">
        <figure class="bg-neutral">
          <img src="../assets/Boon-Relish.png" alt="Boon Relish Logo" />
        </figure>
        <div class="card-body">
          <h1 class="text-2xl mx-auto">
            We are currently not accepting Online Orders
          </h1>
          <h2 class="text-lg mx-auto">
            Please Wait for a while or Contact Store for Urgent Orders
          </h2>
          <div class="divider">Phone Number: 82828282</div>
          <div class="card-actions justify-end">
            <button class="btn btn-neutral hover" @click="getMenu">
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onRenderTracked } from "vue";
import { HTTP } from "./../http-common";
import { useAlertStore } from "./../store/alert";
import { usePageStore } from "./../store/page";
import { useCartStore } from "./../store/cart";
export default defineComponent({
  name: "OrderPage",
  setup() {
    const menu = ref();
    const alert = useAlertStore();
    const page = usePageStore();
    const cart = useCartStore();
    page.change("menu");
    function getMenu() {
      HTTP.get("menu")
        .then((response) => {
          menu.value = response.data.data;
          cart.initialize(menu.value);
        })
        .catch((err) => {
          alert.create("error", "Currently not accepting Orders");
        });
    }
    getMenu();
    return {
      alert,
      menu,
      getMenu,
      cart,
    };
  },
});
</script>
