import { defineStore } from "pinia";

export const useAlertStore = defineStore("alert", {
  state: () => ({ alert: { type: null, message: null } }),
  getters: {
    getAlert: (state) => state.alert,
  },
  actions: {
    create(type, message) {
      this.alert = {
        type: type,
        message: message,
      };
    },
    reset() {
      console.log("Calling reset");
      this.alert = {
        type: null,
        message: null,
      };
    },
  },
});
