import { defineStore } from "pinia";

export const useCartStore = defineStore("cart", {
  state: () => ({
    counter: new Map(),
    menu_id: null,
    cart: {
      food_map: new Map(),
      food_sections: {},
    },
  }),
  getters: {
    getCart: (state) => state.cart,
    getOrderCountFor: (state) => {
      return (i) => {
        if (state.counter.get(i)) return state.counter.get(i);
        return 0;
      };
    },
    getFoodSections: (state) => state.cart.food_sections,
  },
  actions: {
    initialize(menu) {
      if (menu.id != this.menu_id) {
        menu.sections.forEach((section) => {
          section.foods_section.forEach((food) => {
            this.counter.set(food.id, 0);
          });
        });
        this.menu_id = menu.id;
      }
    },

    incrementOrderCountFor(i) {
      this.counter.set(i, this.counter.get(i) + 1);
    },
    decrementOrderCountFor(i) {
      if (this.counter.get(i) > 0) {
        this.counter.set(i, this.counter.get(i) - 1);
      }
    },
    addToCart(section_name, food_section) {
      let i = food_section.id;
      let orderCount = this.getOrderCountFor(i);
      if (this.cart.food_map.has(i)) {
        let index = this.cart.food_map.get(i);
        if (orderCount == 0) {
          this.cart.food_sections[section_name].splice(index, 1);
          this.cart.food_map[section_name].delete(i);
        } else {
          this.cart.food_sections[section_name][index] = {
            count: orderCount,
            food_section: food_section,
          };
        }
      } else {
        if (orderCount > 0) {
          if (!this.cart.food_sections[section_name]) {
            console.log("Not");
            this.cart.food_sections[section_name] = [];
          }
          this.cart.food_map.set(
            i,
            this.cart.food_sections[section_name].length
          );
          this.cart.food_sections[section_name].push({
            count: orderCount,
            food_section: food_section,
          });
        }
      }
    },
  },
});
