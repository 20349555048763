import { defineStore } from "pinia";

export const usePageStore = defineStore("page", {
  state: () => ({ page: "menu" }),
  getters: {
    getPage: (state) => state.page,
  },
  actions: {
    change(page) {
      this.page = page;
    },
  },
});
