import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./index.css";
import { createWebHistory, createRouter } from "vue-router";
import { routes } from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("ion-");
app.use(router).mount("#app");
