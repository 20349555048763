<template>
  <div class="container mx-auto">
    <div v-for="(value, name, index) in cart.getFoodSections" :key="index">
      <div class="divider">
        <div class="text-xl">{{ name }}</div>
      </div>
      <div
        class="flex justify-between"
        v-for="(item, itemIndex) in value"
        :key="itemIndex"
      >
        <div class="flex">
          {{ item.food_section.food.name }}
        </div>
        <div class="flex">
          {{ item.count }} * {{ item.food_section.food.quantity }}
          {{ item.food_section.food.unit }}
        </div>
        <div class="flex">
          Rs {{ item.count * item.food_section.food.price }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useAlertStore } from "@/store/alert";
import { usePageStore } from "@/store/page";
import { useCartStore } from "@/store/cart";
export default {
  name: "CartPage",
  setup() {
    const alert = useAlertStore();
    const page = usePageStore();
    const cart = useCartStore();
    page.change("cart");

    return {
      cart,
    };
  },
};
</script>
