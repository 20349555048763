import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": process.env.VUE_APP_APP_URL,
    "Access-Control-Allow-Methods":
      "GET, POST, PUT, PATCH, POST, DELETE, OPTIONS",
    "Content-Type": "application/json",
  },
});
